<template>
  <footer id="vm-cola-footer-cart" class="vm-cola-footer-cart">
    <div class="vm-cola-footer-cart__content">
      <p class="title">Keranjang belanjamu: <span>Maks. 4 produk</span></p>

      <div class="calculation">
        <div class="calculation__count">
          <img src="/static/img/icons/icon-shop-bg-pink.svg" />
          <span>{{ cart_qty_total }}</span>
        </div>
        <div class="calculation__total">
          <span>{{ total_price | currencyRp }}</span>
        </div>
      </div>

      <div class="action">
        <div
          v-if="$route.name !== 'vmColaOrder'"
          class="action__reset"
          :class="{ disabled: cart_qty_total <= 0 }"
          @click="$emit('reset', cart_qty_total)"
        >
          Ulangi Pesanan
        </div>
        <div
          v-if="!cartShowed || cart_qty_total <= 0"
          class="action__show-cart"
          :class="{ disabled: cart_qty_total <= 0 }"
          @click="$emit('show-cart', cart_qty_total)"
        >
          Lihat Pesanan
        </div>
        <div v-else class="action__checkout" @click="$emit('checkout')">Bayar Sekarang</div>
      </div>
    </div>
    <div class="vm-cola-footer-cart__banner">
      <div class="banner-area">
        <img class="qr-code-sociolla" src="/static/img/icons/qr-code-sociolla.svg" />
        <img class="sociolla-apps" src="/static/img/icons/sociolla-apps.svg" />
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'FooterCart',
  props: {
    cartShowed: {
      type: Boolean,
      default: false,
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    cart_qty_total() {
      return this.$store.state.vmCola.cart_qty;
    },
    point_applied() {
      return this.$store.state.vmCola.point_applied || 0;
    },
    product_list() {
      return this.$store.state.vmCola.product_list;
    },
    total_price() {
      if (this.$route.name === 'vmColaOrder') {
        return this.totalPrice;
      }

      const selectedProducts = this.product_list.filter((p) => p.quantities > 0);

      try {
        if (selectedProducts && selectedProducts.length) {
          let result = 0;
          const data = selectedProducts;

          for (let i = 0; i < data.length; i++) {
            const price = data[i]?.combination?.price_after_discount || data[i].min_price_after_discount || 0;
            result += Number(price * data[i].quantities);
          }

          if (this.point_applied && this.point_applied > 0) {
            result -= this.point_applied;
          }

          return result;
        } else {
          return 0;
        }
      } catch (error) {
        console.log(error);
        return 0;
      }
    },

    current_page() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/sass/mixin-revamp';

.vm-cola-footer-cart {
  height: 350px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  z-index: 23;
  flex-wrap: wrap;
  bottom: 0;
  left: 0;
  border-radius: 23px 23px 0 0;

  img {
    border-radius: 22px 22px 0 0;
    width: 100%;
  }

  &__content {
    flex-grow: 1;
    background-color: #ffd5ea;
    padding: 30px 45px;

    .title {
      font: 28px $lato_bold;
      line-height: 33.6px;
      margin-bottom: 25px;

      span {
        font: 22px $lato_regular;
        font-weight: 600;
        line-height: 26.4px;
        color: #eb395f;
      }
    }

    .calculation {
      display: flex;
      position: relative;
      gap: 15px;

      &__count {
        width: 122px;
        height: 72px;
        padding: 8px 24px 8px 24px;
        border-radius: 50px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 5px;

        span {
          font: 40px $brandon_regular;
          font-weight: 700;
          line-height: 55.6px;
          text-align: center;
        }

        img {
          width: 36px;
          height: 50px;
        }
      }

      &__total {
        font: 50px $lato_regular;
        font-weight: 800;
        line-height: 60px;
      }

      margin-bottom: 30px;
    }

    .action {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 15px;

      &__reset,
      &__show-cart,
      &__checkout {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        border: 1px solid #000;
        border-radius: 39px;
        flex-grow: 1;
        text-align: center;

        font: 28px $lato_regular;
        font-weight: 700;
        line-height: 33.6px;
      }

      &__reset {
        background-color: #fff;
        color: #000;
      }

      &__reset,
      &__show-cart {
        &.disabled {
          background-color: #c0b0b8;
          color: #979797;
          border-color: #c0b0b8;
        }
      }

      &__show-cart,
      &__checkout {
        background-color: #000;
        color: #fff;
      }
    }
  }

  &__banner {
    width: 419px;
    border-radius: 0px 23px 0 0;
    background-color: #ffd5ea;

    .banner-area {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-radius: 26px 23px 0 0;
      background-color: #b12655;
      position: relative;

      .qr-code-sociolla {
        position: absolute;
        top: -45px;
        border-radius: 0;
        width: 200px;
        height: 200px;
      }

      .sociolla-apps {
        border-radius: 0;
        width: 344px;
        height: 74.3px;
        margin-top: 50px;
      }
    }
  }
}
</style>
